import Router from './router/router';
import './styles/style.css'

function App() {
  return (

    <Router/>

  );
}

export default App;
